var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = Transform;

function Transform() {
  (this || _global).x = 0;
  (this || _global).y = 0;
  (this || _global).scale = 1;
}

export default exports;